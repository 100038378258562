import { db} from "@/firebase/config";
import {ref} from "vue";
import PlayerState from "@/types/PlayerState";
import {doc, setDoc, serverTimestamp} from "firebase/firestore";

const updateState = () => {

    const error = ref<string | null>(null)

    const updateState = async (coll:string, id:string, obj:Partial<PlayerState>) => {
        error.value = null
        try {
            const docRef = doc(db, coll, id.toString())
            setDoc(docRef, {
                last_changed: serverTimestamp(),
                state: obj.state,
                room: obj.room,
                playerId: obj.playerId
            })
        } catch (err: any) {
            console.log("Error", err.message)
            error.value = 'Could not update status: ' + err.message
        }
    }
    return { error, updateState }
}

export default updateState
