import { ref } from 'vue'
import { auth, signInWithEmailAndPassword } from '../firebase/config'

const error = ref(null)

const login = async (email, password) => {
  error.value = null

  try {
    signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      // Signed in
      // const user = userCredential.user;
      error.value = null
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      error.value = errorCode + " / " + errorMessage
      console.log(errorCode)
      console.log(errorMessage)
    });
  }
  catch(err) {
    if (err instanceof Error) {
      console.log(err.message)
      error.value = 'Incorrect login credentials'
    }
  }
}

const useLogin = () => {
  return { error, login }
}

export default useLogin
