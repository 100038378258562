// Create a reference to this user's specific status node.
// This is where we will store data about being online/offline.
// import { rtdb } from '@/firebase/config'
import {getDatabase, ref, onValue, onDisconnect, set} from "firebase/database";
import { serverTimestamp } from "firebase/firestore";
// import {UnwrapRef} from "vue";
import updateState from "@/composables/updateState";

const setPresenceState = (id: string, playerId: number, room: number, uid: string) => {

    // const userStatusDatabaseRef = ref(rtdb, `/status/${id}/${uid}`);
    // const userStatusDatabaseRef = firebase.database().ref(`/status/${id}/${uid}`);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    const isOfflineForDatabase = {
        state: 'offline',
        last_changed: serverTimestamp(),
        room: room,
        playerId
    };

    const isOnlineForDatabase = {
        state: 'online',
        last_changed: serverTimestamp(),
        room: room,
        playerId
    };

    // set status online and current room
    const {error: errorState, updateState: updateDoc} = updateState()

    updateDoc(`games/${id}/players`, uid, isOnlineForDatabase)
    if (errorState.value) {
        console.log("updateState error: ", errorState.value)
    }


// Since I can connect from multiple devices or browser tabs, we store each connection instance separately
// any time that connectionsRef's value is null (i.e. has no children) I am offline
    const db = getDatabase();
    const myConnectionsRef = ref(db, `/status/${id}/${uid}`);

// stores the timestamp of my last disconnect (the last time I was seen online)
//     const lastOnlineRef = ref(db, `/status/${id}/${uid}`);

    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snap) => {
        if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
            // const con = push(myConnectionsRef);
            const con = myConnectionsRef

            // When I disconnect, remove this device
            // onDisconnect(con).remove();

            // Add this device to my connections list
            // this value could contain info about the device or a timestamp too
            set(myConnectionsRef, isOnlineForDatabase);

            // When I disconnect, update the last time I was seen online
            onDisconnect(con).set(isOfflineForDatabase);
        }
    });




    /*
    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            return;
        }

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase, error => {
            if (!error) {
                // userStatusDatabaseRef.onDisconnect().remove();
            }
        }).then(function () {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
            console.log("isOnlineForDatabase", isOnlineForDatabase)
/*
            // set status online and current room
            const {error: errorState, updateDoc} = updateState()
            const ts = new firebase.firestore.Timestamp(Math.floor(Date.now() / 1000), 0)
            const status = {
                status: {
                    last_changed: ts,
                    state: 'online'
                },
                room: +room,
                playerId: playerId
            }
            updateDoc(`games/${id}/players`, uid, status)
            if (errorState.value) {
                console.log(errorState.value)
            }
*/
        // });
    // })
}

export default setPresenceState

