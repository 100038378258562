import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '@/views/Home.vue'
import Start from '@/views/Start.vue'
import Room from '@/views/Room.vue'
import End from "@/views/End.vue";
import Error from '@/views/Error.vue'
import Intro from "@/views/Intro.vue";
import Authentification from "@/views/Authentification.vue";
import getGame from "@/composables/getGame";
import {useUserStore} from "@/store/useUserStore";

// game guard
const requireGame = async (to: any, from: any, next: any) => {
  const {error, project} = await getGame(to.params.id)
  const userStore = useUserStore()
  console.log("TO", to)
  console.log("roomDocs.value", Object.assign({}, project))
  console.log("Error: ", error.value)

  if (!error.value) {
    const projectData = JSON.parse(JSON.stringify(project))
    const projectProgress = projectData.progress
    const projectName = projectData.project
    const projectTimestamp = projectData.timestamp
    const projectTimes = projectData.times
    const projectRoom = projectData.rooms
    const projectMaxRooms = projectData.maxRooms
    to.meta.projectName = projectName
    to.meta.projectTimestamp = projectTimestamp
    to.meta.projectProgress = projectProgress
    to.meta.times = projectTimes
    to.meta.projectRoom = projectRoom
    to.meta.projectMaxRooms = projectMaxRooms
    if (userStore.getUid && userStore.playerId && userStore.playerName) {
      console.log("userStore.getUid", userStore.getUid)
      to.meta.uid = userStore.getUid
      to.meta.playerId = userStore.playerId
      to.meta.playerName = userStore.playerName
      to.params.uid = userStore.getUid
      to.params.playerId = userStore.playerId
      to.params.playerName = userStore.playerName
      console.log("TO", to)
      // next(to.fullPath + '1')
    }
      next()
  } else {
    console.log("Game existiert nicht!")
    next('Error')
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:lang/:id/',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/:lang/:id/start',
    name: 'Start',
    component: Start,
    props: true,
    beforeEnter: requireGame,
  },
  {
    path: '/:lang/:id/:room',
    name: 'Room',
    component: Room,
    props: true,
    beforeEnter: requireGame
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "room" */ '../views/Room.vue')
  },
  {
    path: '/:lang/:id/end',
    name: 'End',
      component: End,
    props: true,
    beforeEnter: requireGame
  },
  {
    path: '/admin',
    name: 'Authentification',
    component: Authentification
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  window.scrollTo(0, 0)
})


export default router
