import { ref, watchEffect } from 'vue'
import { db } from '../firebase/config'
import PlayerState from "@/types/PlayerState";
import {collection, onSnapshot} from "firebase/firestore";


const getState = (coll: string) => {

    const documents = ref<PlayerState[] | null>(null)
    const error = ref<string | null>(null)

    const load = async () => {

        // register the firestore collection reference
        const colRef = collection(db, coll)

        const unsub = onSnapshot(colRef, (snap: { docs: any[]; }) => {
            const results: PlayerState[] = []
            snap.docs.forEach(doc => {
                results.push(<PlayerState>{...doc.data(), id: doc.id})
            });
            // update values
            documents.value = results
            error.value = null
        }, (err: { message: any; }) => {
            console.log(err.message)
            documents.value = null
            error.value = 'could not fetch the data'
        })

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });
    }

    return { error, documents, load }
}

export default getState
