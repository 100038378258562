import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "onloadeddata"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("audio", {
    src: _ctx.audio,
    ref: "audioElement",
    onloadeddata: _ctx.play
  }, null, 8, _hoisted_1))
}