
import {defineComponent, onMounted, ref} from "vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: "End",
  components: {Footer, Modal},
  setup: function () {
    const modalContent = ref<string | null>(null)
    const ratingSent = ref<boolean>(false)
    onMounted(() => {
      const lazyClass = 'lazy-loading';
      const lazyImages = document.querySelectorAll(`.${lazyClass}`)
      const lazy0bserver = new IntersectionObserver((elements: any) => {
        elements.forEach((element: any) => {
          if (element.isIntersecting) {
            const image = element.target;
            showImage(image)
            lazy0bserver.unobserve(image)
          }
        })
      })

      lazyImages.forEach(image => {
        lazy0bserver.observe(image);
      })
      function showImage(image: HTMLImageElement) {
        if (image) {
          image.src = image.dataset.src!;
          image.classList.remove(lazyClass)
        }
      }
    })

      const debounce = (e: { (): void; apply?: any; }, t = 300) =>{let u: any;return(...i: any)=>{clearTimeout(u),u=setTimeout(()=>{e.apply(this,i)},t)}}

      document.addEventListener('scroll', debounce(() => {
        if (!ratingSent.value) {
          if(document.documentElement.scrollHeight - (window.innerHeight / 1.5) <= window.scrollY + window.innerHeight) {
            modalContent.value = "Rating"
          }
        }
      }, 500))



    const closeModal = () => {
      ratingSent.value = true
      modalContent.value = null
    }

    return { modalContent, closeModal }
  }
})
