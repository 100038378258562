import {ref} from "vue";
import Progress from "@/types/Progress";
import { db } from "@/firebase/config";
import {doc, getDoc} from "firebase/firestore";

const getGame = async (id: string) => {

    const error = ref<string | null>(null)
    let project: Progress | null = null

    try {
        const docRef = doc(db, 'games', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            error.value = null
            console.log("project", docSnap.data())
            project = {...docSnap.data(), id: docSnap.id} as Progress
        } else {
            console.log("catch: The game does not exists")
            throw new Error('The game does not exists');
        }
    } catch (err: any) {
        project = null
        error.value = 'The game does not exists'
    }
    return { error, project }
}

export default getGame
