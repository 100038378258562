import { ref, watchEffect } from 'vue'
import { db } from '../firebase/config'
import Progress from "@/types/Progress";
import {doc, onSnapshot} from "firebase/firestore";

const getProgress = (coll: string, id: string) => {

    const documents = ref<Progress | null>(null)
    const error = ref<string | null>(null)

    const load = async () => {

        // register the firestore doc reference
        const colRef = doc(db, coll, id)

        const unsub = onSnapshot(colRef, (res) => {
            documents.value = { ...res.data(), id: res.id } as Progress
            error.value = null
        }, (err: { message: any; }) => {
            console.log(err.message)
            documents.value = null
            error.value = 'could not fetch the data'
        })

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        });
    }

    return { error, documents, load }
}

export default getProgress
