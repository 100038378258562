import { ref } from 'vue'
import { db } from '@/firebase/config'
import Room from '@/types/Room'
import {collection, getDocs, limit, query, where} from "firebase/firestore";

const getRoom = (coll: string, conditionField: string, id: number) => {

    const documents = ref<Room[] | null>(null)
    const error = ref<string | null>(null)
    const load = async () => {
        try {
            const colRef = collection(db, coll)
            const q = query(colRef, where(conditionField, "==", id), limit(1))
            getDocs(q).then(res => {
                documents.value = res.docs.map(doc => {
                    return {...doc.data() as Room, id: doc.id}
                })
            })
            error.value = null
        } catch (err) {
            documents.value = null
            if (err instanceof Error) {
                console.log("Error: ", err.message)
                error.value = 'could not fetch the data'
            }
        }
    }

    return { error, documents, load }
}

export default getRoom
