import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export const useUserStore = defineStore('user',{
    state: () => ({
        uid: useSessionStorage('uid', ''),
        playerId: useSessionStorage('playerId', ''),
        playerName: useSessionStorage('playerName', ''),
        isMobile: useSessionStorage('isMobile', ''),
        stripperConfig: useSessionStorage('stripperConfig', '')
    }),
    getters: {
        getUid(): string {
            return this.uid
        },
        getPlayerId(): string {
            return this.playerId
        },
        getPlayerName(): string {
            return this.playerName
        },
        getIsMobile(): string {
            return this.isMobile
        },
        getStripperConfig(): string[] {
            return this.stripperConfig.split(",")
        }
    },
    actions: {
        setUid(uid ?: string) {
            if (!uid) return
            this.uid = uid
        },
        setPlayerId(playerId ?: string) {
            if (!playerId) return
            this.playerId = playerId
        },
        setPlayerName(playerName ?: string) {
            if (!playerName) return
            this.playerName = playerName
        },
        setIsMobile(isMobile ?: string) {
            if (!isMobile) return
            this.isMobile = isMobile
        },
        setStripperConfig(stripperConfig ?: string[]) {
            if (!stripperConfig?.length) return
            this.stripperConfig = stripperConfig.join(",")
        }
    }
})
