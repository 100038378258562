
import {defineComponent} from "vue";
import firebase from "firebase/compat/app"

export default defineComponent ({
  name: "Home",
  setup: function () {

    console.log(process.env);

    const createNewGame = () => {
      console.log("createNewGame")
      const createNewGame = firebase.functions().httpsCallable('createNewGame')
      createNewGame().then(async result => {
        if (result.data !== '') {
          console.log("Success", result.data)
        } else {
          console.log("Failure", result.data)
        }
      })
    }

    return {createNewGame}
  }
})
