import { ref } from 'vue'
import { db } from '@/firebase/config'
import Room from '@/types/Room'
import {collection, getDocs} from "firebase/firestore";

const getPanos = (coll: string) => {

    const documents = ref<Room[] | null>(null)
    const error = ref<string | null>(null)
    const load = async () => {
        try {
            const colRef = collection(db, coll)
            getDocs(colRef).then(res => {
                documents.value = res.docs.map(doc => {
                    return { ...doc.data() as Room, id: doc.id }
                })
                error.value = null
            })
        } catch (err: any) {
            console.log("Error: ", err.message)
            documents.value = null
            error.value = 'could not fetch the data'
        }
    }

    return { error, documents, load }
}

export default getPanos
