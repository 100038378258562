// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, collection, getDocs, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD6pl3SUTP-xHCA3YKJlSVLQZvwrGzN9jc",
    authDomain: "escape-if-you-can-01.firebaseapp.com",
    databaseURL: "https://escape-if-you-can-01-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "escape-if-you-can-01",
    storageBucket: "escape-if-you-can-01.appspot.com",
    messagingSenderId: "1056918206889",
    appId: "1:1056918206889:web:d344983ba39cd0878fecb8"
};

const firebaseConfigTesting = {
    apiKey: "AIzaSyC7ZNMUaHc8fBpxcqcQ5nHEHTCixB1BGf0",
    authDomain: "escape-if-you-can-02.firebaseapp.com",
    databaseURL: "https://escape-if-you-can-02-default-rtdb.firebaseio.com/",
    projectId: "escape-if-you-can-02",
    storageBucket: "escape-if-you-can-02.appspot.com",
    messagingSenderId: "977238315800",
    appId: "1:977238315800:web:ec93a68eed9c24b86bc46d"
};

const ip = '192.168.178.32'


// Initialize Firebase
const app = initializeApp(firebaseConfigTesting);

const dev = process.env.NODE_ENV === 'development'
console.log("DEV", dev)

// init services
const db = getFirestore(app)
if (dev) {
    connectFirestoreEmulator(db, ip, 4000);
}

const rtdb = getDatabase(app)
if (dev) {
    connectDatabaseEmulator(rtdb, ip, 9000);
}

const storage = getStorage(app)

const auth = getAuth(app)
if (dev) {
    connectAuthEmulator(auth, 'http://' + ip + ':9099');
}

const functions = getFunctions(app, 'europe-west1');
if (dev) {
    connectFunctionsEmulator(functions, ip, 5001);
}

// timestamp
// const timestamp = app.firestore.FileValue.serverTimestamp

// init app check for local use
if (dev) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Ldvd9cdAAAAAGxUGRrMc5hCC59fk2yg4-j2ffFm'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

export { db, rtdb, auth, functions, httpsCallable, storage, signInWithEmailAndPassword }
