
import config from "@/config"
import {defineComponent, ref, watch, reactive, computed} from "vue";
import getPanos from "@/composables/getPanos";
import Room from "@/types/Room";
import Hotspot from "@/types/Hotspot";
import {useRoute} from "vue-router";
import router from "@/router";
import {db} from "@/firebase/config";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import useLogout from "@/composables/useLogout";
import {useUserStore} from "@/store/useUserStore";

export default defineComponent({
  name: "AdminPano",
  data() {
    return {
      player1: "0",
      player2: "1",
    }
  },
  props: {
    view: {
      type: Object,
      required: true
    },
    viewer: {
      type: Object,
      required: true
    },
    hotspots: {
      type: Array,
      required: true
    }
  },

  setup(props, context) {
    const route = useRoute()
    const projectName = config.project
    const playerNames = config.player.map(item => item.name)
    const adminState = ref(true)
    const rooms = ref<Room[] | null>(null)
    const userStore = useUserStore()
    const hotspotId = ref<number | string>("")
    const initialHotspotState = {
      name: '',
      yaw: 0 as number,
      pitch: 0 as number,
      type: 'image',
      path: '',
      width: 100,
      height: 100,
      playerId: 0
    }
    const hotspot = reactive<Hotspot>({...initialHotspotState})
    const hotspots = ref<Hotspot[]>(<Hotspot[]>props.hotspots)
    const playerId = ref<number>(+userStore.playerId)

    const view = computed(() => {
      console.log("View Computed", props.view)
      return props.view
    })

    const viewer = computed(() => {
      console.log("Viewer Computed", props.viewer)
      return props.viewer
    })

    const roomId = route.params.room

    // Create list of hotspots
    const hotspotList = computed(() => {
      if (!props.hotspots) return []
      let hsID = 0
      // filter hotspots in current pano
      const filteredHS = hotspots.value.filter((hs: Hotspot) => {
        if (hs.playerId === +playerId.value) {
          return {...hs, id: hsID++}
        }
      })
      // sort hotspots alphabetical
      const sortedHS = filteredHS.sort((a,b) => +(a.name>b.name)||-(a.name<b.name))
      return sortedHS
    })

    const {error, documents, load} = getPanos(`projects/${projectName}/rooms/`)
    load()

    watch(documents, () => {
      if (!error.value) {
        rooms.value = documents.value
      }
    })

    var pano = document.getElementById("pano");
    if (pano) {
      pano.addEventListener("click", function (e) {
        hotspot.yaw = view.value.screenToCoordinates({x: e.clientX, y: e.clientY}).yaw
        hotspot.pitch = view.value.screenToCoordinates({x: e.clientX, y: e.clientY}).pitch
      })
    }

    const chgPlayer = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target) {
        context.emit('update:modelPlayer', +target.value)
        resetHotspotData()
      }
    }

    const changePano = (event: Event) => {
      const target = event.target as HTMLSelectElement
      if (target) {
        router.push({name: 'Room', params: {room: target.value, playerId: playerId.value, playerName: playerNames[+playerId.value], uid: userStore.uid }})
        resetHotspotData()
      }
    }

    const selectHotspot = (event: Event) => {
      const target = event.target as HTMLSelectElement
      if (target && hotspotId.value !== "") {
        const selectedHotspot =  JSON.parse(JSON.stringify(hotspotList.value)).find((item: Hotspot) => item.id === parseInt(target.value))
        hotspot.name = selectedHotspot.name
        hotspot.yaw = selectedHotspot.yaw
        hotspot.pitch = selectedHotspot.pitch
        hotspot.type = selectedHotspot.type
        hotspot.path = selectedHotspot.path
        hotspot.width = selectedHotspot.width
        hotspot.height = selectedHotspot.height
      } else {
        resetHotspotData()
      }
    }

    const placeHotspot = () => {
      const newHotspot = document.createElement('div');
      newHotspot.classList.add('hotspot');
      newHotspot.classList.add('hotspot__new');
      newHotspot.style.width = hotspot.width.toString() + "px"
      newHotspot.style.height = hotspot.height.toString() + "px"
      newHotspot.textContent = hotspot.name
      const scene = viewer.value.scene();
      const hs = scene.hotspotContainer().createHotspot(newHotspot, {
        yaw: hotspot.yaw,
        pitch: hotspot.pitch
      }, { perspective: {
          radius: 1000
      }})
      newHotspot.addEventListener('click', function () {
        scene.hotspotContainer().destroyHotspot(hs)
      });
    }

    const saveHotspot = async () => {
      const docRef = doc(db, `projects/${projectName}/rooms/`, roomId.toString())
      getDoc(docRef).then(snapshot => {
        console.log('snapshot', snapshot)
        if (snapshot.exists()) {
          const panos = snapshot.data()?.panos
          if (hotspotId.value !== ""){
            panos[+playerId.value].hotspots[+hotspotId.value] = JSON.parse(JSON.stringify(hotspot))
          } else {
            panos[+playerId.value].hotspots.push(JSON.parse(JSON.stringify(hotspot)))
          }

          updateDoc(docRef, {
            "panos": panos
          })
          .then(() => {
            console.log("Document successfully updated!");
            context.emit('loadPanoData', null)
            resetHotspotData()
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }

    const deleteHotspot = async () => {
      const docRef = doc(db, `projects/${projectName}/rooms`, roomId.toString())
      getDoc(docRef).then((snapshot) => {
        if (snapshot.exists()) {
          const panos = snapshot.data()?.panos
          panos[+playerId.value].hotspots.splice(hotspotId.value, 1)

          updateDoc(docRef, {
            "panos": panos
          })
              .then(() => {
                console.log("Document successfully updated!");
                context.emit('loadPanoData', null)
                resetHotspotData()
              })
              .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }

    const resetHotspotData = () => {
      Object.assign(hotspot, initialHotspotState);
      hotspotId.value = ''
    }

    watch(adminState, () => {
      document.body.classList.toggle('admin')
    })

    const { error: logoutError, logout } = useLogout()

    const handleLogout = async () => {
      await logout()
      if (!logoutError.value) {
        console.log("User logged out")
      }
    }

    return {playerId, rooms, hotspot, hotspotId, hotspotList, roomId, changePano, placeHotspot, saveHotspot, selectHotspot, deleteHotspot, chgPlayer, adminState, handleLogout}
  }
})
