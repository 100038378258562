import {ref} from "vue";
import {collection, limit, onSnapshot, query} from "firebase/firestore";
import {db} from "@/firebase/config";

const useCheckExistenceOfColl = (coll: string) => {

  const docExists = ref<boolean | null>(null)
  const error = ref<string | null>(null)

  const load = async () => {
    try {
      const colRef = collection(db, coll)
      const q = query(colRef, limit(1))
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
            console.log("querySnapshot.empty", querySnapshot.empty)
            docExists.value = !querySnapshot.empty
            if (docExists.value) {
              unsubscribe()
            }
          },
          (error) => {
            console.log("Snapshot listener failed!", error.message)
          });
      error.value = null
    } catch (err) {
      docExists.value = false
      if (err instanceof Error) {
        console.log("Error: ", err.message)
        error.value = 'collection does not exist'
      }
    }
  }

  return {error, docExists, load}
}

export default useCheckExistenceOfColl
