

import {defineComponent} from 'vue';
import router from "@/router";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {Footer},

  setup() {
    const goToStart = () => {
      router.push({
        name: 'Start'
      })
    }

    return {goToStart}
  }
})


