
import {defineComponent, ref} from "vue";

export default defineComponent ({
  name: "Audio",
  props: {
    audio: {
      type: String,
      required: true
    },
    playAudio: {
      type: Number,
      required: true
    }
  },
  watch: {
    playAudio: {
      immediate: true,
      handler() {
        this.replay()
      },
    },
  },
  setup(props) {
    const audioElement = ref<HTMLAudioElement | null>(null);
    const audioFile = ref<string>('')

    const play = () => {
      audioElement.value?.play()
    }

    const replay = () => {
      if (audioElement.value?.src === props.audio) {
        audioElement.value?.play()
      }
    }

    return { audioElement, audioFile, play, replay }
  }
})
